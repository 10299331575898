import { TranslateService } from '@ngx-translate/core';
import { Language } from '../enum/language.enum';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var AAATranslateService = /** @class */ (function () {
    function AAATranslateService(translateService) {
        this.translateService = translateService;
        this.currentLanguage = Language.ENGLISH;
        this.translateService.setDefaultLang(AAATranslateService.DEFAULT_TRANSLATION);
        this.loadTokenLanguage();
    }
    AAATranslateService.prototype.changeLanguage = function (language) {
        this.currentLanguage = language;
        this.translateService.use(language);
    };
    AAATranslateService.prototype.get = function (str) {
        return this.translateService.get(str) || null;
    };
    AAATranslateService.prototype.loadTokenLanguage = function () {
        var trusteapi = window['trusteapi'];
        try {
            var tokenLocale = trusteapi.auth.tokenInfo.locale;
            switch (tokenLocale) {
                case Language.FRENCH:
                    this.changeLanguage(Language.FRENCH);
                    break;
                case Language.GERMAN:
                    this.changeLanguage(Language.GERMAN);
                    break;
                default:
                    this.changeLanguage(Language.ENGLISH);
            }
        }
        catch (e) {
            this.changeLanguage(Language.ENGLISH);
        }
    };
    AAATranslateService.DEFAULT_TRANSLATION = Language.ENGLISH;
    AAATranslateService.ngInjectableDef = i0.defineInjectable({ factory: function AAATranslateService_Factory() { return new AAATranslateService(i0.inject(i1.TranslateService)); }, token: AAATranslateService, providedIn: "root" });
    return AAATranslateService;
}());
export { AAATranslateService };
