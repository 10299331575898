import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class SysInfoService {
  constructor(private restAngular: Restangular) {}

  getInfo() {
    return this.restAngular.one('sys_info').get();
  }
}
