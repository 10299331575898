import { Injectable } from '@angular/core';

import { AccountService } from '../modules/aaacommon/api/account.service';
import { Account } from '../modules/aaacommon/interface/account';
import { environment } from '../../environments/environment';
import { User } from '../modules/aaacommon/interface/user';
import { Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  currentUser: User = <User>{ id: 'superuser' };
  isSuperAdmin = false;
  isAdmin = false;
  isUser = false;
  isNoAccess = false;
  isReseller = false;

  private _isAdminReseller = false;
  private isAdminResellerSubject = new Subject<boolean>();

  /**
   * An asynchronous way of checking whether the current user
   * is either a Super Admin or an Admin under a Reseller-type account.
   */
  get isAdminReseller(): Observable<boolean> {
    return this.isAdminResellerSubject.closed
      ? of(this._isAdminReseller)
      : this.isAdminResellerSubject.asObservable();
  }

  constructor(private accountService: AccountService) {
    this.setCurrentUser(environment.userInfo);
  }

  setCurrentUser(user: User) {
    if (!user) { return; }
    this.currentUser = user;
    this.accountService
      .getAccount(user.accountId)
      .subscribe(
        (account: Account) => {
          this.isReseller = account.type === 'Reseller';
          this.emitIsAdminReseller(this.isAdmin && this.isReseller);
        }
      );
    const application = user.applicationRoles.find(
      app => app.application === 'RiverheadClient'
    );
    if (application) {
      if (application.userRole === 'riverhead_super_admin') {
        this.isSuperAdmin = true;
        this.emitIsAdminReseller(true);
      } else if (application.userRole === 'riverhead_admin') {
        this.isAdmin = true;
      } else if (application.userRole === 'riverhead_user') {
        this.isUser = true;
      } else if (application.userRole === 'riverhead_no_access') {
        this.isNoAccess = true;
      }
    } else {
      this.isNoAccess = true;
    }
  }

  private emitIsAdminReseller(value: boolean) {
    if (!this.isAdminResellerSubject.closed) {
      this._isAdminReseller = value;
      this.isAdminResellerSubject.next(value);
      this.isAdminResellerSubject.unsubscribe();
    }
  }

  canEditThisUser(user: User) {
    if(this.isSuperAdmin) {
      return true;
    }
    const hasInternalRole = user.applicationRoles
      .map(roleObj => roleObj.userRole)
      .find((role) => this.isSuperAdminRole(role));
    const hasInternalUserGroup = user.userGroups && user.userGroups
      .map(userGroup => userGroup.name)
      .find((role) => this.isSuperAdminRole(role));
    if (hasInternalRole || hasInternalUserGroup) {
      return false;
    }
    return true;
  }

  isSuperAdminRole(role: string) {
    if(role && (role.endsWith("_super_admin") ||
      role.endsWith("_superadmin") ||
      role.endsWith("_internal") ||
      role.endsWith("_internal_admin") ||
      role.endsWith("_internaladmin"))) {
      return true;
    }
    return false;
  }
}
