import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {Account} from '../interface/account';
import {environment} from 'src/environments/environment';
import {Application} from '../interface/application';
import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {GenericPaginatedList} from '../interface/generic-paginated-list';
import {HttpClient, HttpParams} from "@angular/common/http";
import {CustomHttpParamEncoder} from "../../../util/customhttpparamencoder";

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private restAngular: Restangular, private http: HttpClient) {}

  getAccount(
    accountId: string,
    includeExtension: boolean = false
  ) {
    const parameters = {
      includeExtension
    };
    return this.restAngular.one('accounts', accountId).get(parameters);
  }

  getAccounts(
    itemsPerPage: number = 10,
    page: number = 0,
    searchLegalName: string = '',
    order: string = 'ASC',
    columnName: string = 'legalName',
    showSubAccounts: boolean = true
  ) {
    const parameters = {
      size: itemsPerPage,
      page: page,
      order: order,
      columnName: columnName,
      showSubAccounts: showSubAccounts,
      legalName: searchLegalName
    };
    return this.restAngular
      .one('accounts')
      .get(parameters)
      .pipe(
        catchError(error => {
          console.error('Error fetching accounts', {
            parameters,
            error
          });
          const emptyPage: GenericPaginatedList<Account> = {
            content: [],
            totalElements: 0,
            totalPages: 0
          } as GenericPaginatedList<Account>;
          return of(emptyPage);
        })
      );
  }

  getAll(params?: any): Observable<any> {
    const url = `${environment.apiEndpoint}/accounts`;
    const pp = new HttpParams({ fromObject: params, encoder: new CustomHttpParamEncoder() })
    return this.http.get(url, { params: pp });
  }

  getByLegalName(legalName: string, params?: any) {
    return this.restAngular
      .one('accounts')
      .one('legalname', legalName)
      .get(params);
  }

  getByNymityId(nymityId: string, params?: any) {
    return this.restAngular
      .one('accounts')
      .one('nymityid', nymityId)
      .get(params);
  }

  add(account: Account, includeExtension: boolean = false) {
    const parameters = {
      includeExtension
    };
    return this.restAngular.all('accounts').customPOST(account, '', parameters);
  }

  update(accountId: string, account: Account, includeExtension: boolean = false) {
    const parameters = {
      includeExtension
    };
    return this.restAngular.one('accounts', accountId).customPUT(account, '', parameters);
  }

  enable(accountId: string) {
    return this.restAngular.one('accounts', accountId).one('enable').post();
  }

  disable(accountId: string) {
    return this.restAngular.one('accounts', accountId).one('disable').post();
  }

  emailActivations(accountId: string) {
    return this.restAngular.one('accounts', accountId).one('activations').post();
  }

  getMetatags(
    accountId: string,
    required: boolean = false,
    page: number = 0,
    size: number = 50
  ) {
    const params = { page, size, required };
    return this.restAngular
      .one('accounts', accountId)
      .one('metatags')
      .get(params);
  }

  downloadUser(params: { accountId: string; applications: Application[] }) {
    return this.restAngular
      .one('accounts', params.accountId)
      .one('users')
      .one('download')
      .customGET(null, {
        applications: params.applications.map(app => app.clientId)
      })
      .pipe(map((restAngular: Restangular) => restAngular.plain()));
  }

  downloadUserActions(accountId: string, params: { createdStart: number, createdEnd: number }) {
    return this.restAngular
      .one('accounts', accountId)
      .one('useractions')
      .one('download')
      .get(params);
  }
}
