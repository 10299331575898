import {EventEmitter, Injectable, NgZone} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {CurrentUserService} from './current-user.service';
import {environment} from '../../environments/environment';
import {AlertService} from '../modules/aaacommon/services/alert.service';
import {PlatformNavigation} from '../platform-navigation';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlatformNavService {
  static $menuSelected = new Subject<string>();

  private _isReady = false;
  private _currentSelected: string[] | null = null;

  private navEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router,
              private ngZone: NgZone) {
    PlatformNavService.$menuSelected.subscribe((url) => {
      this.ngZone.run(() => {
        this.navigateByUrl(url);
      });
    });
  }

  getNavEmitter() {
    return this.navEventEmitter;
  }

  navigate(data: any) {
    console.log('ROUTING TO: ' + data.item.url);
    const url = data.item.url;
    this.navigateByUrl(url);
  }

  navigateByUrl(url: string) {
    const currentRouterUrl = this.router.url;
    let accountId = environment.userInfo.accountId;
    const matches = currentRouterUrl.match(/\/accounts\/(.*?)\//);
    if (matches && matches[1]) {
      accountId = matches[1];
    }

    if (url.indexOf('account-details') >= 0) {
      this.router.navigate([
        '/accounts',
        accountId,
        'details'
      ]);
    } else if (url.indexOf('account-permission-profiles') >= 0) {
      this.router.navigate([
        '/accounts',
        accountId,
        'permission-profiles'
      ]);
    } else if (url.indexOf('account-tag-groups') >= 0 ) {
      this.router.navigate([
        '/accounts',
        accountId,
        'tag-groups',
        'list'
      ]);
    }  else if (url.indexOf('account-tag-associations') >= 0 ) {
      this.router.navigate([
        '/accounts',
        accountId,
        'tag-groups',
        'associations'
      ]);
    }  else if (url.indexOf('account-tag-settings') >= 0 ) {
      this.router.navigate([
        '/accounts',
        accountId,
        'tag-groups',
        'account-settings'
      ]);
    }  else if (url.indexOf('account-metadata') >= 0 ) {
      this.router.navigate([
        '/accounts',
        accountId,
        'meta',
        'list'
      ]);
    }  else if (url.indexOf('account-api-credentials') >= 0 ) {
      this.router.navigate([
        '/accounts',
        accountId,
        'credentials'
      ]);
    }  else if (url.indexOf('account-reports') >= 0 ) {
      this.router.navigate([
        '/accounts',
        accountId,
        'reports'
      ]);
    }  else if (url.indexOf('account-users') >= 0 ) {
      this.router.navigate([
        '/accounts',
        accountId,
        'users'
      ]);
    } else if (url.indexOf('account-user-groups') >= 0 ) {
      this.router.navigate([
        '/accounts',
        accountId,
        'user-groups'
      ]);
    } else if (url.indexOf('account-permission-groups') >= 0 ) {
      this.router.navigate([
        '/accounts',
        accountId,
        'groups'
      ]);
    }  else if (url.indexOf('account-trails') >= 0 ) {
      this.router.navigate([
        '/accounts',
        accountId,
        'trails'
      ]);
    }  else if (url.indexOf('account-settings') >= 0 ) {
      this.router.navigate([
        '/accounts',
        accountId,
        'settings'
      ]);
    }  else if (url.indexOf('all-accounts') >= 0 ) {
      this.router.navigate([
        '/accounts']);
    }  else if (url.indexOf('all-userprofiles') >= 0 ) {
      this.router.navigate([
        '/user-profile']);
    }  else if (url.indexOf('configuration') >= 0 || url.indexOf('system-information') >= 0) {
      this.router.navigate([url]);
    } else {
      console.log(`Route not Found, Path: ${url}`);
    }
  }

  selectMenu(menu: string[]) {
    window.trustArcPlatformNavigation.selectMenu(menu);
  }
}
