import { EventEmitter, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var PlatformNavService = /** @class */ (function () {
    function PlatformNavService(router, ngZone) {
        var _this = this;
        this.router = router;
        this.ngZone = ngZone;
        this._isReady = false;
        this._currentSelected = null;
        this.navEventEmitter = new EventEmitter();
        PlatformNavService.$menuSelected.subscribe(function (url) {
            _this.ngZone.run(function () {
                _this.navigateByUrl(url);
            });
        });
    }
    PlatformNavService.prototype.getNavEmitter = function () {
        return this.navEventEmitter;
    };
    PlatformNavService.prototype.navigate = function (data) {
        console.log('ROUTING TO: ' + data.item.url);
        var url = data.item.url;
        this.navigateByUrl(url);
    };
    PlatformNavService.prototype.navigateByUrl = function (url) {
        var currentRouterUrl = this.router.url;
        var accountId = environment.userInfo.accountId;
        var matches = currentRouterUrl.match(/\/accounts\/(.*?)\//);
        if (matches && matches[1]) {
            accountId = matches[1];
        }
        if (url.indexOf('account-details') >= 0) {
            this.router.navigate([
                '/accounts',
                accountId,
                'details'
            ]);
        }
        else if (url.indexOf('account-permission-profiles') >= 0) {
            this.router.navigate([
                '/accounts',
                accountId,
                'permission-profiles'
            ]);
        }
        else if (url.indexOf('account-tag-groups') >= 0) {
            this.router.navigate([
                '/accounts',
                accountId,
                'tag-groups',
                'list'
            ]);
        }
        else if (url.indexOf('account-tag-associations') >= 0) {
            this.router.navigate([
                '/accounts',
                accountId,
                'tag-groups',
                'associations'
            ]);
        }
        else if (url.indexOf('account-tag-settings') >= 0) {
            this.router.navigate([
                '/accounts',
                accountId,
                'tag-groups',
                'account-settings'
            ]);
        }
        else if (url.indexOf('account-metadata') >= 0) {
            this.router.navigate([
                '/accounts',
                accountId,
                'meta',
                'list'
            ]);
        }
        else if (url.indexOf('account-api-credentials') >= 0) {
            this.router.navigate([
                '/accounts',
                accountId,
                'credentials'
            ]);
        }
        else if (url.indexOf('account-reports') >= 0) {
            this.router.navigate([
                '/accounts',
                accountId,
                'reports'
            ]);
        }
        else if (url.indexOf('account-users') >= 0) {
            this.router.navigate([
                '/accounts',
                accountId,
                'users'
            ]);
        }
        else if (url.indexOf('account-user-groups') >= 0) {
            this.router.navigate([
                '/accounts',
                accountId,
                'user-groups'
            ]);
        }
        else if (url.indexOf('account-permission-groups') >= 0) {
            this.router.navigate([
                '/accounts',
                accountId,
                'groups'
            ]);
        }
        else if (url.indexOf('account-trails') >= 0) {
            this.router.navigate([
                '/accounts',
                accountId,
                'trails'
            ]);
        }
        else if (url.indexOf('account-settings') >= 0) {
            this.router.navigate([
                '/accounts',
                accountId,
                'settings'
            ]);
        }
        else if (url.indexOf('all-accounts') >= 0) {
            this.router.navigate([
                '/accounts'
            ]);
        }
        else if (url.indexOf('all-userprofiles') >= 0) {
            this.router.navigate([
                '/user-profile'
            ]);
        }
        else if (url.indexOf('configuration') >= 0 || url.indexOf('system-information') >= 0) {
            this.router.navigate([url]);
        }
        else {
            console.log("Route not Found, Path: " + url);
        }
    };
    PlatformNavService.prototype.selectMenu = function (menu) {
        window.trustArcPlatformNavigation.selectMenu(menu);
    };
    PlatformNavService.$menuSelected = new Subject();
    PlatformNavService.ngInjectableDef = i0.defineInjectable({ factory: function PlatformNavService_Factory() { return new PlatformNavService(i0.inject(i1.Router), i0.inject(i0.NgZone)); }, token: PlatformNavService, providedIn: "root" });
    return PlatformNavService;
}());
export { PlatformNavService };
