import { enableProdMode, Injector, NgZone } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ArcService } from "./app/services";
import { environment } from "./environments/environment";
import { environmentLoader as environmentLoaderPromise } from "./environments/environmentLoader";
import { loadStaticHeader } from "./environments/staticheader";
import { PlatformNavService } from './app/services/platform-nav.service';
import { NavBreadcrumbsService } from "./app/services/nav-breadcrumbs.service";
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
function injectProviderJs() {
    var scriptTag = document.createElement("script");
    scriptTag.async = true;
    scriptTag.src = environment.providerJsURL;
    scriptTag.type = "text/javascript";
    var scripts = document.getElementsByTagName("script");
    if (scripts.length > 0) {
        var parentNode = scripts.item(0).parentNode;
        parentNode.insertBefore(scriptTag, scripts.item(0));
    }
}
function registerOnSignInCallback() {
    var authObj = window;
    authObj.onSignInCallback = onSignInCallback;
}
function onSignInCallback(response) {
    if (response.signedIn) {
        console.log("authResult received in onSignInCallback");
        var authObj = window;
        var trusteapi_1 = authObj.trusteapi;
        if (trusteapi_1) {
            console.log("trustapi works!");
            environment.token = response.token.token;
            trusteapi_1.user.getUserInfo(function (userInfo) {
                environment.userInfo = userInfo;
                environment.trusteapi = trusteapi_1;
                loadStaticHeader();
                __NgCli_bootstrap_2.platformBrowser([{ provide: ArcService, useValue: arcService }])
                    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
                    .catch(function (err) { return console.log(err); });
            });
        }
    }
}
var ɵ0 = document, ɵ1 = function () { return new NgZone({}); };
var injector = Injector.create({
    providers: [
        { provide: ArcService, useClass: ArcService, deps: [DOCUMENT, NgZone] },
        { provide: DOCUMENT, useValue: ɵ0 },
        {
            provide: NgZone,
            useFactory: ɵ1,
            deps: [],
        },
    ],
});
var arcService = injector.get(ArcService);
environmentLoaderPromise.then(function (env) {
    if (environment.production) {
        enableProdMode();
        environment.apiEndpoint = env.apiEndpoint;
        environment.providerJsURL = env.providerJsURL;
        environment.tagServerApiEndpoint = env.tagServerApiEndpoint;
        environment.enableDebug = env.enableDebug;
        environment.auditTrailApiEndpoint = env.auditTrailApiEndpoint;
        environment.baseUrl = env.baseUrl;
        environment.arcUrl = env.arcUrl;
        environment.arcSubdirectory = env.arcSubdirectory;
        environment.arcEnabled = env.arcEnabled;
    }
    arcService.init(function () {
        onSignInCallback(window.trusteapi.auth);
        initNavigation();
    });
    if (!arcService.fromArc) {
        registerOnSignInCallback();
        window.platformNavConfig = {
            appContainerId: "main-container",
            onReady: function () {
                initNavigation();
            },
        };
    }
    if (!window["Cypress"]) {
        if (!arcService.fromArc) {
            injectProviderJs();
        }
    }
});
function initNavigation() {
    window.trustArcPlatformNavigation.on("menuSelected", function (_a) {
        var item = _a.item;
        PlatformNavService.$menuSelected.next(item.url);
    });
    NavBreadcrumbsService.isReady();
}
export { ɵ0, ɵ1 };
