import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

import { PlatformNavigation } from "../platform-navigation";

@Injectable({
  providedIn: "root",
})
export class NavBreadcrumbsService {
  private static isReadySubject = new BehaviorSubject<boolean>(false);
  private static isReady$ = NavBreadcrumbsService.isReadySubject.asObservable();

  private _isReady = false;
  private _currentBreadcrumbs: PlatformNavigation.BreadcrumbItem[] | null =
    null;

  constructor(private router: Router) {
    NavBreadcrumbsService.isReady$.subscribe((isReady) => {
      if (isReady) {
        this._isReady = true;

        if (this._currentBreadcrumbs) {
          this.create(this._currentBreadcrumbs);
        }

        window.trustArcPlatformNavigation.breadcrumbs.on("item_click", ({ item }) => {
          this.navigateToRoute(item.href);
        });

        NavBreadcrumbsService.isReadySubject.unsubscribe();
      }
    });
  }

  static isReady() {
    NavBreadcrumbsService.isReadySubject.next(true);
  }

  create(items: PlatformNavigation.BreadcrumbItem[]) {
    if (this._isReady) {
      if (
        window.trustArcPlatformNavigation.breadcrumbs &&
        window.trustArcPlatformNavigation.breadcrumbs.create
      ) {
        window.trustArcPlatformNavigation.breadcrumbs.create(items);
      }
    } else {
      this._currentBreadcrumbs = items;
    }
  }

  navigateToRoute(href?: string) {
    if (href) {
      this.router.navigateByUrl(href);
    }
  }
}
