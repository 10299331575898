export const environment = {
  production: true,
  baseUrl: 'https://aaa-dev.truste-svc.net',
  apiEndpoint: 'https://aaa-dev.truste-svc.net/api/v1',
  providerJsURL: 'https://aaa-dev.truste-svc.net/aaa-js/js/provider.js',
  tagServerApiEndpoint: 'https://tagserver-dev.truste-svc.net/tagserver/api',
  auditTrailApiEndpoint: 'https://dpmp-audit-dev.truste-svc.net/audit-trail/audit/v1',
  switchUrl: 'https://aaa-dev.truste-svc.net',
  arcUrl: "https://arc.trustarc.com",
  arcSubdirectory: "admin",
  arcEnabled: false,
  enableDebug: false,
  trusteapi: null,
  token: null,
  userInfo: null
};
