import { SysInfoService } from './modules/aaacommon/api/sys-info.service';
import { Observable } from 'rxjs';
import { Restangular } from 'ngx-restangular';
import { map } from 'rxjs/operators';
import { SystemInfo } from './modules/aaacommon/interface/system-info';
import { MomentService } from './services/moment.service';
import { AAATranslateService } from './modules/aaacommon/services/aaa-translate.service';
import { PlatformNavService } from './services/platform-nav.service';
import { Router } from '@angular/router';
import { Component, OnInit, Renderer2 } from "@angular/core";
import { ArcService } from "./services";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  systemInfo$: Observable<string>;

  constructor(
    private sysInfoApi: SysInfoService,
    private momentService: MomentService,
    private translate: AAATranslateService,
    private platformNavService: PlatformNavService,
    private router: Router,
    private renderer: Renderer2,
    private arcService: ArcService
  ) {
    this.arcService.initListeners(this.router, this.renderer);
  }

  ngOnInit(): void {
    this.systemInfo$ = this.sysInfoApi
      .getInfo()
      .pipe(
        map((restAngular: Restangular) => restAngular.plain()),
        map((build: SystemInfo) => `version: ${build.version} timestamp:${this.momentService.formatTimeStamp(build.buildTimestamp)}`)
      );
    this.translate.loadTokenLanguage();
    const currentRouterUrl = this.router.url;
    const data = {
      item : {
        url: currentRouterUrl
      }
    }
    this.platformNavService.navigate(data);
  }
}
