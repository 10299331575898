import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserService } from '../services/current-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private currentUserService: CurrentUserService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.currentUserService.isNoAccess || this.currentUserService.isUser) {
      this.router.navigate(['/no-access']);
      return false;
    } else if (state.url === '/') {
      this.router.navigate([
        'accounts',
        this.currentUserService.currentUser.accountId,
        'details'
      ]);
      return false;
    }
    return true;
  }
}
