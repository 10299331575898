import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { Restangular } from 'ngx-restangular';
import { map, catchError } from 'rxjs/operators';
import { Screen } from '../enum/screen.enum';
import { Translation } from '../interface/translation';

export class TranslationLoaderService implements TranslateLoader {
  constructor(private restAngular: Restangular) {}

  getTranslation(lang: string): Observable<any> {
    const request = this.restAngular
      .one('translations')
      .one('screen', Screen.ADMIN)
      .one('locale', lang)
      .get()
      .pipe(
        catchError(() => of({ plain: () => of({}) })),
        map((restAngular: Restangular) => restAngular.plain()),
        map((translationData: Translation) => translationData.translation)
      );
    return request;
  }
}
