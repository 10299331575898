import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../enum/language.enum';
import { Trusteapi } from 'src/app/interfaces/trusteapi';

@Injectable({
  providedIn: 'root'
})
export class AAATranslateService {
  public static readonly DEFAULT_TRANSLATION = Language.ENGLISH;
  currentLanguage = Language.ENGLISH;

  constructor(
    private translateService: TranslateService,
  ) {
    this.translateService.setDefaultLang(
      AAATranslateService.DEFAULT_TRANSLATION
    );
    this.loadTokenLanguage();
  }

  changeLanguage(language: Language) {
    this.currentLanguage = language;
    this.translateService.use(language);
  }

  get(str: string) {
    return this.translateService.get(str) || null;
  }

  loadTokenLanguage() {
    const trusteapi = <Trusteapi>window['trusteapi'];
    try {
      const tokenLocale = trusteapi.auth.tokenInfo.locale;
      switch (tokenLocale) {
        case Language.FRENCH:
          this.changeLanguage(Language.FRENCH);
          break;
        case Language.GERMAN:
          this.changeLanguage(Language.GERMAN);
          break;
        default:
          this.changeLanguage(Language.ENGLISH);
      }
    } catch (e) {
      this.changeLanguage(Language.ENGLISH);
    }
  }
}
