import { enableProdMode, Injector, NgZone } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { DOCUMENT } from "@angular/common";
import { AppModule } from "./app/app.module";
import { ArcService } from "./app/services";
import { environment } from "./environments/environment";
import { environmentLoader as environmentLoaderPromise } from "./environments/environmentLoader";
import { loadStaticHeader } from "./environments/staticheader";
import {PlatformNavService} from './app/services/platform-nav.service';
import {PlatformNavigation} from './app/platform-navigation';
import MenuSelectedEventData = PlatformNavigation.MenuSelectedEventData;
import { NavBreadcrumbsService } from "./app/services/nav-breadcrumbs.service";

export interface AuthenticationObject {
  profileComponent: any;
  onSignInCallback: any;
  trusteapi: any;
}

function injectProviderJs() {
  const scriptTag = document.createElement("script");
  scriptTag.async = true;
  scriptTag.src = environment.providerJsURL;
  scriptTag.type = "text/javascript";
  const scripts = document.getElementsByTagName("script");
  if (scripts.length > 0) {
    const parentNode = scripts.item(0).parentNode;
    parentNode.insertBefore(scriptTag, scripts.item(0));
  }
}

function registerOnSignInCallback() {
  const authObj = <AuthenticationObject>(<any>window);
  authObj.onSignInCallback = onSignInCallback;
}

function onSignInCallback(response: any) {
  if (response.signedIn) {
    console.log("authResult received in onSignInCallback");
    const authObj = <any>window;
    const trusteapi = authObj.trusteapi;
    if (trusteapi) {
      console.log("trustapi works!");
      environment.token = response.token.token;
      trusteapi.user.getUserInfo((userInfo) => {
        environment.userInfo = userInfo;
        environment.trusteapi = trusteapi;
        loadStaticHeader();
        platformBrowserDynamic([{ provide: ArcService, useValue: arcService }])
          .bootstrapModule(AppModule)
          .catch((err) => console.log(err));
      });
    }
  }
}

const injector = Injector.create({
  providers: [
    { provide: ArcService, useClass: ArcService, deps: [DOCUMENT, NgZone] },
    { provide: DOCUMENT, useValue: document },
    {
      provide: NgZone,
      useFactory: () => new NgZone({}),
      deps: [],
    },
  ],
});
const arcService = injector.get(ArcService);

environmentLoaderPromise.then((env) => {
  if (environment.production) {
    enableProdMode();
    environment.apiEndpoint = env.apiEndpoint;
    environment.providerJsURL = env.providerJsURL;
    environment.tagServerApiEndpoint = env.tagServerApiEndpoint;
    environment.enableDebug = env.enableDebug;
    environment.auditTrailApiEndpoint = env.auditTrailApiEndpoint;
    environment.baseUrl = env.baseUrl;
    environment.arcUrl = env.arcUrl;
    environment.arcSubdirectory = env.arcSubdirectory;
    environment.arcEnabled = env.arcEnabled;
  }

  arcService.init(() => {
    onSignInCallback((window as any).trusteapi.auth);
    initNavigation();
  });

  if (!arcService.fromArc) {
    registerOnSignInCallback();

    window.platformNavConfig = {
      appContainerId: "main-container",
      onReady: () => {
        initNavigation();
      },
    };
  }

  if (!window["Cypress"]) {
    if (!arcService.fromArc) {
      injectProviderJs();
    }
  }
});

function initNavigation() {
  window.trustArcPlatformNavigation.on(
    "menuSelected",
    ({ item }: MenuSelectedEventData) => {
      PlatformNavService.$menuSelected.next(item.url);
    }
  );

  NavBreadcrumbsService.isReady();
}
