import { BrowserModule, DOCUMENT } from "@angular/platform-browser";
import {
  InjectionToken,
  NgModule,
  DoBootstrap,
  ApplicationRef,
  NgZone,
  Inject,
} from "@angular/core";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { environment } from "../environments/environment";
import { Restangular, RestangularModule } from "ngx-restangular";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslationLoaderService } from "./modules/aaacommon/services/translation-loader.service";
import { NoAccessComponent } from "./no-access/no-access.component";
import { AuthService } from "./services/auth.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { PlatformNavService } from "./services/platform-nav.service";
import { Platform } from "@angular/cdk/platform";
import { AuthInterceptor } from "./auth-interceptor";

export function RestangularConfigFactory(
  RestangularProvider,
  authservice: AuthService
) {
  RestangularProvider.setBaseUrl(environment.apiEndpoint);
  RestangularProvider.setDefaultHeaders({
    Authorization: "Bearer " + environment.token,
  });
  RestangularProvider.addErrorInterceptor((response) => {
    if (response.status === 401) {
      authservice.logout();
      return false; // error handled
    }
    return true; // error not handled
  });
}

export const RestangularTag = new InjectionToken<any>("RestangularTag");
export function RestangularTagFactory(restangular: Restangular) {
  return restangular.withConfig((RestangularConfigurer) => {
    RestangularConfigurer.setBaseUrl(environment.tagServerApiEndpoint);
  });
}

@NgModule({
  declarations: [AppComponent, NoAccessComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RestangularModule.forRoot(
      [AuthService, PlatformNavService],
      RestangularConfigFactory
    ),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (restAngular: Restangular) =>
          new TranslationLoaderService(restAngular),
        deps: [Restangular],
      },
    }),
    HttpClientModule,
  ],
  exports: [],
  providers: [
    {
      provide: RestangularTag,
      useFactory: RestangularTagFactory,
      deps: [Restangular],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
  }
}
