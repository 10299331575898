var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NgZone, Renderer2 } from "@angular/core";
import { NavigationEnd } from "@angular/router";
import { environment } from "src/environments/environment";
import { PlatformNavService } from "./platform-nav.service";
var ArcService = /** @class */ (function () {
    function ArcService(document, ngZone) {
        this.document = document;
        this.ngZone = ngZone;
        this.arcAthKey = "ARC_AUTH";
        this.arcNavigateKey = "ARC_NAVIGATE";
        this.arcBackDropKey = "ARC_BACKDROP";
        this.arcSubAppUrlChangeKey = "ARC_SUB_APP_URL_CHANGE";
        this.isOverlayPresent = false;
        this.router = null;
    }
    Object.defineProperty(ArcService.prototype, "fromArc", {
        get: function () {
            var queryString = this.document.location.hash.split("?")[1];
            var queryParams = new URLSearchParams(queryString);
            var arcParam = queryParams.get("arc");
            if (!arcParam) {
                return false;
            }
            return ["1", "true"].includes(arcParam.trim().toLowerCase());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ArcService.prototype, "arcUrl", {
        get: function () {
            return environment.arcUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ArcService.prototype, "arcSubdirectory", {
        get: function () {
            return environment.arcSubdirectory;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ArcService.prototype, "arcEnabled", {
        get: function () {
            return environment.arcEnabled;
        },
        enumerable: true,
        configurable: true
    });
    ArcService.prototype.init = function (readyCallback) {
        if (this.fromArc) {
            this.bindToMessageListener(readyCallback);
            this.addGlobalStyle();
        }
        else if (this.arcEnabled) {
            this.document.location.href = this.arcUrl + "/" + this.arcSubdirectory + this.document.location.pathname + this.document.location.search + this.document.location.hash;
        }
    };
    ArcService.prototype.initListeners = function (router, renderer) {
        this.router = router;
        if (this.fromArc) {
            this.bindToRouterEvents();
            this.observeOverlayInBody(renderer);
        }
    };
    ArcService.prototype.navigateTo = function (path) {
        window.parent.postMessage({
            type: this.arcNavigateKey,
            path: path,
        }, "*");
    };
    ArcService.prototype.bindToMessageListener = function (readyCallback) {
        var _this = this;
        this.ngZone.runOutsideAngular(function () {
            window.addEventListener("message", function (event) {
                if (!event.data || !event.data.type) {
                    return;
                }
                switch (event.data.type) {
                    case _this.arcAthKey:
                        {
                            window.trustArcPlatformNavigation = __assign({}, JSON.parse(event.data.trustArcPlatformNavigation), { on: function () { }, selectMenu: function () { }, breadcrumbs: {
                                    create: function () { },
                                    add: function () { },
                                    back: function () { },
                                    clear: function () { },
                                    current: function () { },
                                    item: function () { },
                                    select: function () { },
                                    setRootItem: function () { },
                                    removeRootItem: function () { },
                                    on: function () { },
                                    items: function () { return []; },
                                } });
                            window.truste = JSON.parse(event.data.truste);
                            window.trusteapi = JSON.parse(event.data.trusteapi);
                            window.trusteapi.user.getUserInfo = function (callback) {
                                fetch(environment.baseUrl + "/oauth/userinfo", {
                                    method: "POST",
                                    headers: {
                                        Authorization: "Bearer " + environment.token,
                                    },
                                })
                                    .then(function (response) { return response.json(); })
                                    .then(function (userInfo) {
                                    callback(userInfo);
                                });
                            };
                            _this.ngZone.run(function () { return readyCallback(); });
                        }
                        break;
                    case _this.arcNavigateKey:
                        _this.ngZone.run(function () {
                            if (event.data.path.startsWith("/#/")) {
                                event.data.path = event.data.path.substring(2);
                            }
                            PlatformNavService.$menuSelected.next(event.data.path);
                        });
                        break;
                }
            });
        });
    };
    ArcService.prototype.bindToRouterEvents = function () {
        var _this = this;
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                var url = "/#" + event.urlAfterRedirects;
                window.parent.postMessage({
                    type: _this.arcSubAppUrlChangeKey,
                    url: url,
                }, "*");
            }
        });
    };
    ArcService.prototype.addGlobalStyle = function () {
        var styleSheet = this.document.createElement("style");
        styleSheet.innerHTML = "\n  ta-platform-navigation {\n    display: none !important;\n  }\n\n  #main-container {\n    display: block !important;\n    padding: 0 !important;\n  }\n\n  .main-content {\n    height: 100vh !important;\n  }\n\n  .main-side-nav-container {\n    height: calc(100vh - 120px) !important;\n  }\n  ";
        this.document.head.appendChild(styleSheet);
    };
    ArcService.prototype.observeOverlayInBody = function (renderer) {
        var _this = this;
        var bodyElement = renderer.selectRootElement("body", true);
        var observer = new MutationObserver(function () {
            var overlayExists = !!_this.document.querySelector(".p-component-overlay") ||
                !!_this.document.querySelector(".modal-backdrop");
            if (overlayExists && !_this.isOverlayPresent) {
                window.parent.postMessage({ type: _this.arcBackDropKey, value: true }, "*");
                _this.isOverlayPresent = true;
            }
            else if (!overlayExists && _this.isOverlayPresent) {
                window.parent.postMessage({ type: _this.arcBackDropKey, value: false }, "*");
                _this.isOverlayPresent = false;
            }
        });
        observer.observe(bodyElement, {
            attributes: true,
            attributeFilter: ["class"],
        });
    };
    return ArcService;
}());
export { ArcService };
