import * as moment from 'moment-timezone';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
var MomentService = /** @class */ (function () {
    function MomentService(authService) {
        this.authService = authService;
    }
    MomentService.prototype.formatTimeStamp = function (buildTimeStamp, isSeconds) {
        var tokenInfo = this.authService.getTokenInfo();
        if (tokenInfo) {
            var zoneInfo = this.authService.getTokenInfo().zoneInfo;
            if (zoneInfo) {
                if (isSeconds) {
                    return moment.unix(buildTimeStamp)
                        .tz(zoneInfo)
                        .format();
                }
                else {
                    return moment(buildTimeStamp)
                        .tz(zoneInfo)
                        .format();
                }
            }
        }
        if (isSeconds) {
            return moment.unix(buildTimeStamp).format();
        }
        else {
            return moment(buildTimeStamp).format();
        }
    };
    MomentService.ngInjectableDef = i0.defineInjectable({ factory: function MomentService_Factory() { return new MomentService(i0.inject(i1.AuthService)); }, token: MomentService, providedIn: "root" });
    return MomentService;
}());
export { MomentService };
