import { InjectionToken, } from "@angular/core";
import { environment } from "../environments/environment";
import { Restangular } from "ngx-restangular";
import { TranslationLoaderService } from "./modules/aaacommon/services/translation-loader.service";
import { AuthService } from "./services/auth.service";
export function RestangularConfigFactory(RestangularProvider, authservice) {
    RestangularProvider.setBaseUrl(environment.apiEndpoint);
    RestangularProvider.setDefaultHeaders({
        Authorization: "Bearer " + environment.token,
    });
    RestangularProvider.addErrorInterceptor(function (response) {
        if (response.status === 401) {
            authservice.logout();
            return false; // error handled
        }
        return true; // error not handled
    });
}
export var RestangularTag = new InjectionToken("RestangularTag");
export function RestangularTagFactory(restangular) {
    return restangular.withConfig(function (RestangularConfigurer) {
        RestangularConfigurer.setBaseUrl(environment.tagServerApiEndpoint);
    });
}
var ɵ0 = function (restAngular) {
    return new TranslationLoaderService(restAngular);
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
