/* eslint-disable @typescript-eslint/no-explicit-any */
import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, NgZone, Renderer2 } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { PlatformNavService } from "./platform-nav.service";

@Injectable()
export class ArcService {
  readonly arcAthKey = "ARC_AUTH";
  readonly arcNavigateKey = "ARC_NAVIGATE";
  readonly arcBackDropKey = "ARC_BACKDROP";
  readonly arcSubAppUrlChangeKey = "ARC_SUB_APP_URL_CHANGE";

  get fromArc(): boolean {
    const queryString = this.document.location.hash.split("?")[1];
    const queryParams = new URLSearchParams(queryString);
    const arcParam = queryParams.get("arc");
    if (!arcParam) {
      return false;
    }

    return ["1", "true"].includes(arcParam.trim().toLowerCase());
  }

  private isOverlayPresent = false;
  private router: Router = null;

  private get arcUrl(): string {
    return environment.arcUrl;
  }

  private get arcSubdirectory(): string {
    return environment.arcSubdirectory;
  }

  private get arcEnabled(): boolean {
    return environment.arcEnabled;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone
  ) {}

  init(readyCallback: () => void): void {
    if (this.fromArc) {
      this.bindToMessageListener(readyCallback);
      this.addGlobalStyle();
    } else if (this.arcEnabled) {
      this.document.location.href = `${this.arcUrl}/${this.arcSubdirectory}${this.document.location.pathname}${this.document.location.search}${this.document.location.hash}`;
    }
  }

  initListeners(router: Router, renderer: Renderer2): void {
    this.router = router;

    if (this.fromArc) {
      this.bindToRouterEvents();
      this.observeOverlayInBody(renderer);
    }
  }

  navigateTo(path: string): void {
    window.parent.postMessage(
      {
        type: this.arcNavigateKey,
        path: path,
      },
      "*"
    );
  }

  private bindToMessageListener(readyCallback: () => void) {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener("message", (event) => {
        if (!event.data || !event.data.type) {
          return;
        }

        switch (event.data.type) {
          case this.arcAthKey:
            {
              (window as any).trustArcPlatformNavigation = {
                ...JSON.parse(event.data.trustArcPlatformNavigation),
                on: () => {},
                selectMenu: () => {},
                breadcrumbs: {
                  create: () => {},
                  add: () => {},
                  back: () => {},
                  clear: () => {},
                  current: () => {},
                  item: () => {},
                  select: () => {},
                  setRootItem: () => {},
                  removeRootItem: () => {},
                  on: () => {},
                  items: () => [],
                },
              };
              (window as any).truste = JSON.parse(event.data.truste);
              (window as any).trusteapi = JSON.parse(event.data.trusteapi);
              (window as any).trusteapi.user.getUserInfo = (
                callback: (userinfo) => void
              ) => {
                fetch(`${environment.baseUrl}/oauth/userinfo`, {
                  method: "POST",
                  headers: {
                    Authorization: `Bearer ${environment.token}`,
                  },
                })
                  .then((response) => response.json())
                  .then((userInfo: any) => {
                    callback(userInfo);
                  });
              };
              this.ngZone.run(() => readyCallback());
            }
            break;

          case this.arcNavigateKey:
            this.ngZone.run(() => {
              if (event.data.path.startsWith("/#/")) {
                event.data.path = event.data.path.substring(2);
              }

              PlatformNavService.$menuSelected.next(event.data.path);
            });
            break;
        }
      });
    });
  }

  private bindToRouterEvents() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = `/#${event.urlAfterRedirects}`;
        window.parent.postMessage(
          {
            type: this.arcSubAppUrlChangeKey,
            url: url,
          },
          "*"
        );
      }
    });
  }

  private addGlobalStyle() {
    const styleSheet = this.document.createElement("style");
    styleSheet.innerHTML = `
  ta-platform-navigation {
    display: none !important;
  }

  #main-container {
    display: block !important;
    padding: 0 !important;
  }

  .main-content {
    height: 100vh !important;
  }

  .main-side-nav-container {
    height: calc(100vh - 120px) !important;
  }
  `;
    this.document.head.appendChild(styleSheet);
  }

  private observeOverlayInBody(renderer: Renderer2): void {
    const bodyElement = renderer.selectRootElement("body", true);
    const observer = new MutationObserver(() => {
      const overlayExists =
        !!this.document.querySelector(".p-component-overlay") ||
        !!this.document.querySelector(".modal-backdrop");

      if (overlayExists && !this.isOverlayPresent) {
        window.parent.postMessage(
          { type: this.arcBackDropKey, value: true },
          "*"
        );
        this.isOverlayPresent = true;
      } else if (!overlayExists && this.isOverlayPresent) {
        window.parent.postMessage(
          { type: this.arcBackDropKey, value: false },
          "*"
        );
        this.isOverlayPresent = false;
      }
    });

    observer.observe(bodyElement, {
      attributes: true,
      attributeFilter: ["class"],
    });
  }
}
