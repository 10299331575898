
export function loadStaticHeader() {

window['staticHeaderConfig'] = {
    /*theme: 'dark', // this will be the Look and Feel. The default value is 'white'. The CSS of every themes will be in the topheader js.
     logo: '/images/truste-logo-white.png',*/
    title: false, // whether to display the clientName or not. default: true
    fixed: true, // whether the top header position is fixed on top
    showBusinessName: true,
    menu: [
      { text: 'Accounts', url: '#/accounts', role: 'riverhead_admin', accountType: 'Reseller' },
      { text: 'Accounts', url: '#/accounts', role: 'riverhead_super_admin' },
      { text: 'User Profiles', url: '#/user-profile', role: 'riverhead_super_admin' },
      {
        text: 'Configuration', role: 'riverhead_super_admin' ,
        menu: [
          { text: 'Applications', url: '#/configuration/applications', role: 'riverhead_super_admin' },
          // { text: 'Asset Types', url: '#/asset_types', role: 'riverhead_super_admin' },
          { text: 'Email Templates', url: '#/configuration/email-templates', role: 'riverhead_super_admin' },
          { text: 'Entities', url: '#/configuration/entities', role: 'riverhead_super_admin' },
          { text: 'External Auth', url: '#/configuration/xauths', role: 'riverhead_super_admin' },
          { text: 'Platform Navigation', url: '#/configuration/platform-navigation', role: 'riverhead_super_admin' },
          { text: 'SFTP Setup', url: '#/configuration/sftp_setup', role: 'riverhead_super_admin' }
        ]
      },
      {
        text: 'System Information', role: 'riverhead_super_admin',
        menu: [
          { text: 'Overview', url: '#/system-information/overview', role: 'riverhead_super_admin' },
          { text: 'History',  url: '#/system-information/history', role: 'riverhead_super_admin' },
          { text: 'Jobs',     url: '#/system-information/jobs', role: 'riverhead_super_admin' },
          { text: 'Reports',  url: '#/system-information/reports', role: 'riverhead_super_admin' },
          { text: 'Metrics',  url: '#/system-information/metrics', role: 'riverhead_super_admin' },
          { text: 'Translations',  url: '#/system-information/translations', role: 'riverhead_super_admin' },
          { text: 'Global Settings',  url: '#/system-information/global-settings', role: 'riverhead_super_admin' },
          { text: 'Nimity Accounts Sync',  url: '#/system-information/nymity-acct-sync', role: 'riverhead_super_admin' },
          { text: 'Nimity Users Sync',  url: '#/system-information/nymity-users-sync', role: 'riverhead_super_admin' }
        ]
      }
    ]
  };
}
