import { Restangular } from 'ngx-restangular';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient, HttpParams } from "@angular/common/http";
import { CustomHttpParamEncoder } from "../../../util/customhttpparamencoder";
import * as i0 from "@angular/core";
import * as i1 from "ngx-restangular";
import * as i2 from "@angular/common/http";
var AccountService = /** @class */ (function () {
    function AccountService(restAngular, http) {
        this.restAngular = restAngular;
        this.http = http;
    }
    AccountService.prototype.getAccount = function (accountId, includeExtension) {
        if (includeExtension === void 0) { includeExtension = false; }
        var parameters = {
            includeExtension: includeExtension
        };
        return this.restAngular.one('accounts', accountId).get(parameters);
    };
    AccountService.prototype.getAccounts = function (itemsPerPage, page, searchLegalName, order, columnName, showSubAccounts) {
        if (itemsPerPage === void 0) { itemsPerPage = 10; }
        if (page === void 0) { page = 0; }
        if (searchLegalName === void 0) { searchLegalName = ''; }
        if (order === void 0) { order = 'ASC'; }
        if (columnName === void 0) { columnName = 'legalName'; }
        if (showSubAccounts === void 0) { showSubAccounts = true; }
        var parameters = {
            size: itemsPerPage,
            page: page,
            order: order,
            columnName: columnName,
            showSubAccounts: showSubAccounts,
            legalName: searchLegalName
        };
        return this.restAngular
            .one('accounts')
            .get(parameters)
            .pipe(catchError(function (error) {
            console.error('Error fetching accounts', {
                parameters: parameters,
                error: error
            });
            var emptyPage = {
                content: [],
                totalElements: 0,
                totalPages: 0
            };
            return of(emptyPage);
        }));
    };
    AccountService.prototype.getAll = function (params) {
        var url = environment.apiEndpoint + "/accounts";
        var pp = new HttpParams({ fromObject: params, encoder: new CustomHttpParamEncoder() });
        return this.http.get(url, { params: pp });
    };
    AccountService.prototype.getByLegalName = function (legalName, params) {
        return this.restAngular
            .one('accounts')
            .one('legalname', legalName)
            .get(params);
    };
    AccountService.prototype.getByNymityId = function (nymityId, params) {
        return this.restAngular
            .one('accounts')
            .one('nymityid', nymityId)
            .get(params);
    };
    AccountService.prototype.add = function (account, includeExtension) {
        if (includeExtension === void 0) { includeExtension = false; }
        var parameters = {
            includeExtension: includeExtension
        };
        return this.restAngular.all('accounts').customPOST(account, '', parameters);
    };
    AccountService.prototype.update = function (accountId, account, includeExtension) {
        if (includeExtension === void 0) { includeExtension = false; }
        var parameters = {
            includeExtension: includeExtension
        };
        return this.restAngular.one('accounts', accountId).customPUT(account, '', parameters);
    };
    AccountService.prototype.enable = function (accountId) {
        return this.restAngular.one('accounts', accountId).one('enable').post();
    };
    AccountService.prototype.disable = function (accountId) {
        return this.restAngular.one('accounts', accountId).one('disable').post();
    };
    AccountService.prototype.emailActivations = function (accountId) {
        return this.restAngular.one('accounts', accountId).one('activations').post();
    };
    AccountService.prototype.getMetatags = function (accountId, required, page, size) {
        if (required === void 0) { required = false; }
        if (page === void 0) { page = 0; }
        if (size === void 0) { size = 50; }
        var params = { page: page, size: size, required: required };
        return this.restAngular
            .one('accounts', accountId)
            .one('metatags')
            .get(params);
    };
    AccountService.prototype.downloadUser = function (params) {
        return this.restAngular
            .one('accounts', params.accountId)
            .one('users')
            .one('download')
            .customGET(null, {
            applications: params.applications.map(function (app) { return app.clientId; })
        })
            .pipe(map(function (restAngular) { return restAngular.plain(); }));
    };
    AccountService.prototype.downloadUserActions = function (accountId, params) {
        return this.restAngular
            .one('accounts', accountId)
            .one('useractions')
            .one('download')
            .get(params);
    };
    AccountService.ngInjectableDef = i0.defineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.inject(i1.Restangular), i0.inject(i2.HttpClient)); }, token: AccountService, providedIn: "root" });
    return AccountService;
}());
export { AccountService };
