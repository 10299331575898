import { Injectable } from '@angular/core';
import { Trusteapi } from '../interfaces/trusteapi';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor() {}

  getToken() {
    const auth = this.getAuth();
    return auth ? auth.token.token : null;
  }
  getTokenInfo() {
    const auth = this.getAuth();
    return auth ? auth.tokenInfo : null;
  }

  getAuth() {
    const trusteapi = <Trusteapi>window['trusteapi'];
    if (trusteapi === null) {
      return null;
    }
    return trusteapi.auth === null ? null : trusteapi.auth;
  }

  logout() {
    const element: HTMLAnchorElement = document.createElement('a');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.href = `${environment.baseUrl}/logout`;
    element.click();
    element.remove();
  }
}
