
    <div ui-view>
      <h3 align="center" class="posi" style="margin-top:5%">
        This solution is not enabled for your account. Please
        <a href="https://www.trustarc.com/about/contact/" target="_blank"
          >contact us</a
        >
        to learn more.
      </h3>
    </div>
  