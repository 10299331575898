import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
var AuthService = /** @class */ (function () {
    function AuthService() {
    }
    AuthService.prototype.getToken = function () {
        var auth = this.getAuth();
        return auth ? auth.token.token : null;
    };
    AuthService.prototype.getTokenInfo = function () {
        var auth = this.getAuth();
        return auth ? auth.tokenInfo : null;
    };
    AuthService.prototype.getAuth = function () {
        var trusteapi = window['trusteapi'];
        if (trusteapi === null) {
            return null;
        }
        return trusteapi.auth === null ? null : trusteapi.auth;
    };
    AuthService.prototype.logout = function () {
        var element = document.createElement('a');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.href = environment.baseUrl + "/logout";
        element.click();
        element.remove();
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
