import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './guards/auth.guard';
import { SuperAdminGuardGuard } from './guards/super-admin-guard.guard';
import { NoAccessComponent } from './no-access/no-access.component';

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: AppComponent
  },
  {
    path: 'no-access',
    pathMatch: 'full',
    component: NoAccessComponent
  },
  {
    path: 'system-information',
    canActivate: [AuthGuard, SuperAdminGuardGuard],
    loadChildren:
      './modules/system-information/system-information.module#SystemInformationModule'
  },
  {
    path: 'accounts',
    loadChildren: './modules/accounts/accounts.module#AccountsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'configuration',
    canActivate: [AuthGuard, SuperAdminGuardGuard],
    loadChildren:
      './modules/configuration/configuration.module#ConfigurationModule'
  },
  {
    path: 'user-profile',
    canActivate: [AuthGuard, SuperAdminGuardGuard],
    loadChildren: './modules/user-profile/user-profile.module#UserProfileModule'
  },
  // Old Routing to New Routing
  {
    path: 'reports',
    pathMatch: 'full',
    redirectTo: 'system-information/reports'
  },
  {
    path: 'sys_info',
    pathMatch: 'full',
    redirectTo: 'system-information/overview'
  },
  {
    path: 'history',
    pathMatch: 'full',
    redirectTo: 'system-information/history'
  },
  {
    path: 'jobs',
    pathMatch: 'full',
    redirectTo: 'system-information/jobs'
  },
  {
    path: 'jobs/:id',
    pathMatch: 'full',
    redirectTo: 'system-information/jobs/:id'
  },
  {
    path: 'metrics',
    pathMatch: 'full',
    redirectTo: 'system-information/metrics'
  },
  {
    path: 'applications',
    pathMatch: 'full',
    redirectTo: 'configuration/applications'
  },
  {
    path: 'email-templates',
    pathMatch: 'full',
    redirectTo: 'configuration/email-templates'
  },
  {
    path: 'xauths',
    pathMatch: 'full',
    redirectTo: 'configuration/xauths'
  },
  {
    path: 'sftp_setup',
    pathMatch: 'full',
    redirectTo: 'configuration/sftp_setup'
  },
  {
    path: 'entities',
    pathMatch: 'full',
    redirectTo: 'configuration/entities'
  },
  {
    path: 'users',
    pathMatch: 'full',
    redirectTo: 'user-profile'
  },
  {
    path: 'users/profile/:id/users',
    pathMatch: 'full',
    redirectTo: 'user-profile/:id'
  },
  {
    path: '**',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: AppComponent
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(appRoutes, { useHash: true, enableTracing: true })],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule {}
