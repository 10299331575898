import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MomentService {
  constructor(private authService: AuthService) {}

  formatTimeStamp(buildTimeStamp: number, isSeconds?: boolean) {
    const tokenInfo = this.authService.getTokenInfo();
    if (tokenInfo) {
      const zoneInfo = this.authService.getTokenInfo().zoneInfo;
      if (zoneInfo) {
        if (isSeconds) {
          return moment.unix(buildTimeStamp)
            .tz(zoneInfo)
            .format();
        } else {
          return moment(buildTimeStamp)
            .tz(zoneInfo)
            .format();
        }
      }
    }
    if (isSeconds) {
      return moment.unix(buildTimeStamp).format();
    } else {
      return moment(buildTimeStamp).format();
    }
  }
}
