import { CurrentUserService } from '../services/current-user.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/current-user.service";
var SuperAdminGuardGuard = /** @class */ (function () {
    function SuperAdminGuardGuard(currentUser) {
        this.currentUser = currentUser;
    }
    SuperAdminGuardGuard.prototype.canActivate = function (next, state) {
        return this.currentUser.isSuperAdmin;
    };
    SuperAdminGuardGuard.ngInjectableDef = i0.defineInjectable({ factory: function SuperAdminGuardGuard_Factory() { return new SuperAdminGuardGuard(i0.inject(i1.CurrentUserService)); }, token: SuperAdminGuardGuard, providedIn: "root" });
    return SuperAdminGuardGuard;
}());
export { SuperAdminGuardGuard };
