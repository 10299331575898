import { AccountService } from '../modules/aaacommon/api/account.service';
import { environment } from '../../environments/environment';
import { of, Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../modules/aaacommon/api/account.service";
var CurrentUserService = /** @class */ (function () {
    function CurrentUserService(accountService) {
        this.accountService = accountService;
        this.currentUser = { id: 'superuser' };
        this.isSuperAdmin = false;
        this.isAdmin = false;
        this.isUser = false;
        this.isNoAccess = false;
        this.isReseller = false;
        this._isAdminReseller = false;
        this.isAdminResellerSubject = new Subject();
        this.setCurrentUser(environment.userInfo);
    }
    Object.defineProperty(CurrentUserService.prototype, "isAdminReseller", {
        /**
         * An asynchronous way of checking whether the current user
         * is either a Super Admin or an Admin under a Reseller-type account.
         */
        get: function () {
            return this.isAdminResellerSubject.closed
                ? of(this._isAdminReseller)
                : this.isAdminResellerSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CurrentUserService.prototype.setCurrentUser = function (user) {
        var _this = this;
        if (!user) {
            return;
        }
        this.currentUser = user;
        this.accountService
            .getAccount(user.accountId)
            .subscribe(function (account) {
            _this.isReseller = account.type === 'Reseller';
            _this.emitIsAdminReseller(_this.isAdmin && _this.isReseller);
        });
        var application = user.applicationRoles.find(function (app) { return app.application === 'RiverheadClient'; });
        if (application) {
            if (application.userRole === 'riverhead_super_admin') {
                this.isSuperAdmin = true;
                this.emitIsAdminReseller(true);
            }
            else if (application.userRole === 'riverhead_admin') {
                this.isAdmin = true;
            }
            else if (application.userRole === 'riverhead_user') {
                this.isUser = true;
            }
            else if (application.userRole === 'riverhead_no_access') {
                this.isNoAccess = true;
            }
        }
        else {
            this.isNoAccess = true;
        }
    };
    CurrentUserService.prototype.emitIsAdminReseller = function (value) {
        if (!this.isAdminResellerSubject.closed) {
            this._isAdminReseller = value;
            this.isAdminResellerSubject.next(value);
            this.isAdminResellerSubject.unsubscribe();
        }
    };
    CurrentUserService.prototype.canEditThisUser = function (user) {
        var _this = this;
        if (this.isSuperAdmin) {
            return true;
        }
        var hasInternalRole = user.applicationRoles
            .map(function (roleObj) { return roleObj.userRole; })
            .find(function (role) { return _this.isSuperAdminRole(role); });
        var hasInternalUserGroup = user.userGroups && user.userGroups
            .map(function (userGroup) { return userGroup.name; })
            .find(function (role) { return _this.isSuperAdminRole(role); });
        if (hasInternalRole || hasInternalUserGroup) {
            return false;
        }
        return true;
    };
    CurrentUserService.prototype.isSuperAdminRole = function (role) {
        if (role && (role.endsWith("_super_admin") ||
            role.endsWith("_superadmin") ||
            role.endsWith("_internal") ||
            role.endsWith("_internal_admin") ||
            role.endsWith("_internaladmin"))) {
            return true;
        }
        return false;
    };
    CurrentUserService.ngInjectableDef = i0.defineInjectable({ factory: function CurrentUserService_Factory() { return new CurrentUserService(i0.inject(i1.AccountService)); }, token: CurrentUserService, providedIn: "root" });
    return CurrentUserService;
}());
export { CurrentUserService };
