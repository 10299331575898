import { Restangular } from 'ngx-restangular';
import * as i0 from "@angular/core";
import * as i1 from "ngx-restangular";
var SysInfoService = /** @class */ (function () {
    function SysInfoService(restAngular) {
        this.restAngular = restAngular;
    }
    SysInfoService.prototype.getInfo = function () {
        return this.restAngular.one('sys_info').get();
    };
    SysInfoService.ngInjectableDef = i0.defineInjectable({ factory: function SysInfoService_Factory() { return new SysInfoService(i0.inject(i1.Restangular)); }, token: SysInfoService, providedIn: "root" });
    return SysInfoService;
}());
export { SysInfoService };
