import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var NavBreadcrumbsService = /** @class */ (function () {
    function NavBreadcrumbsService(router) {
        var _this = this;
        this.router = router;
        this._isReady = false;
        this._currentBreadcrumbs = null;
        NavBreadcrumbsService.isReady$.subscribe(function (isReady) {
            if (isReady) {
                _this._isReady = true;
                if (_this._currentBreadcrumbs) {
                    _this.create(_this._currentBreadcrumbs);
                }
                window.trustArcPlatformNavigation.breadcrumbs.on("item_click", function (_a) {
                    var item = _a.item;
                    _this.navigateToRoute(item.href);
                });
                NavBreadcrumbsService.isReadySubject.unsubscribe();
            }
        });
    }
    NavBreadcrumbsService.isReady = function () {
        NavBreadcrumbsService.isReadySubject.next(true);
    };
    NavBreadcrumbsService.prototype.create = function (items) {
        if (this._isReady) {
            if (window.trustArcPlatformNavigation.breadcrumbs &&
                window.trustArcPlatformNavigation.breadcrumbs.create) {
                window.trustArcPlatformNavigation.breadcrumbs.create(items);
            }
        }
        else {
            this._currentBreadcrumbs = items;
        }
    };
    NavBreadcrumbsService.prototype.navigateToRoute = function (href) {
        if (href) {
            this.router.navigateByUrl(href);
        }
    };
    NavBreadcrumbsService.isReadySubject = new BehaviorSubject(false);
    NavBreadcrumbsService.isReady$ = NavBreadcrumbsService.isReadySubject.asObservable();
    NavBreadcrumbsService.ngInjectableDef = i0.defineInjectable({ factory: function NavBreadcrumbsService_Factory() { return new NavBreadcrumbsService(i0.inject(i1.Router)); }, token: NavBreadcrumbsService, providedIn: "root" });
    return NavBreadcrumbsService;
}());
export { NavBreadcrumbsService };
