import { Router } from '@angular/router';
import { CurrentUserService } from '../services/current-user.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/current-user.service";
import * as i2 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(currentUserService, router) {
        this.currentUserService = currentUserService;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        if (this.currentUserService.isNoAccess || this.currentUserService.isUser) {
            this.router.navigate(['/no-access']);
            return false;
        }
        else if (state.url === '/') {
            this.router.navigate([
                'accounts',
                this.currentUserService.currentUser.accountId,
                'details'
            ]);
            return false;
        }
        return true;
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.CurrentUserService), i0.inject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
