/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./no-access.component";
var styles_NoAccessComponent = [];
var RenderType_NoAccessComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NoAccessComponent, data: {} });
export { RenderType_NoAccessComponent as RenderType_NoAccessComponent };
export function View_NoAccessComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["ui-view", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "h3", [["align", "center"], ["class", "posi"], ["style", "margin-top:5%"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" This solution is not enabled for your account. Please "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "a", [["href", "https://www.trustarc.com/about/contact/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["contact us"])), (_l()(), i0.ɵted(-1, null, [" to learn more. "]))], null, null); }
export function View_NoAccessComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-access", [], null, null, null, View_NoAccessComponent_0, RenderType_NoAccessComponent)), i0.ɵdid(1, 114688, null, 0, i1.NoAccessComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoAccessComponentNgFactory = i0.ɵccf("app-no-access", i1.NoAccessComponent, View_NoAccessComponent_Host_0, {}, {}, []);
export { NoAccessComponentNgFactory as NoAccessComponentNgFactory };
